<template>
  <b-card class="store-order-statement">
    <div class="wrap-order-transaction-buttons">
      <b-button
        variant="primary"
        @click="setDate(0)"
      >
        {{ $t('Today') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="setDate(-1)"
      >
        {{ $t('Yesterday') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="setDate(-7)"
      >
        {{ $t('Current Week') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="setDate(-14)"
      >
        {{ $t('Previous Week') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="setDate(-30)"
      >
        {{ $t('Current Month') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="setDate(-60)"
      >
        {{ $t('Previous Month') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="setDate(-365)"
      >
        {{ $t('Current Year') }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="setDate(-730)"
      >
        {{ $t('Previous Year') }}
      </b-button>
    </div>
    <div class="filter-dates mt-2">
      <div class="row">
        <div class="col-md-4">
          <label for="datepicker-default">{{ $t('Date From') }}</label>
          <b-form-datepicker
            v-model="filterDates.stateDate"
            local="en"
            class="mb-2"
            today-variant="danger"
          />
        </div>
        <div class="col-md-4">
          <label for="datepicker-default">{{ $t('Date To') }}</label>
          <b-form-datepicker
            v-model="filterDates.endDate"
            local="en"
            class="mb-2"
            today-variant="danger"
          />
        </div>
        <div class="col-md-2">
          <label>{{ $t('Status') }}</label>
          <b-form-select
            v-model="filterDates.status"
            :options="statusList"
          />
        </div>
        <div class="col-md-2 text-left d-flex flex-wrap align-items-center my-1">
          <b-button
            variant="primary"
            @click="filterData"
          >
            {{ $t('Submit') }}
          </b-button>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="statementRef"
          :columns="columns"
          :total-column="'response.responseData.orders.total'"
          :data-column="'response.responseData.orders.data'"
          :api-endpoint="'/shop/transactions'"
          :extra-params="extraParams"
          :initialize-on-load="false"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BFormDatepicker, BRow, BCol, BFormSelect } from 'bootstrap-vue'
import moment from 'moment'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  components: {
    BCard,
    BFormDatepicker,
    BRow,
    BCol,
    GoodDataTable,
    BFormSelect,
  },
  data() {
    return {
      filterDates: {
        stateDate: new Date(),
        endDate: new Date(),
      },
      extraParams: 'transaction_type=order&type=range',
      columns: [
        {
          label: 'Order Number',
          field: 'store_order_invoice_id',
        },
        {
          label: 'User Name',
          field: 'user',
          useRenderer: true,
          renderer: props => {
            if (props.user) {
              return `${props.user.first_name} ${props.user.last_name}`
            }
            return ''
          },
        },
        {
          label: 'Date & Time',
          field: 'dated',
          useRenderer: true,
          renderer: props => props.created_time,
        },
        {
          label: 'Status',
          field: 'status',
          useRenderer: true,
          align: 'center',
          renderer: props => props.status,
        },
        {
          label: 'Amount',
          field: 'amount',
          useRenderer: true,
          renderer: props => {
            if (props.invoice) {
              return `${props.currency}${props.invoice.total_amount}`
            }
            return ''
          },
        },
        {
          label: 'Commission',
          field: 'commission',
          useRenderer: true,
          renderer: props => {
            if (props.invoice) {
              return `${props.currency}${props.invoice.commision_amount}`
            }
            return ''
          },
        },
        {
          label: 'Payment Type',
          field: 'amount_type',
          useRenderer: true,
          renderer: props => {
            if (props.invoice) {
              return `${props.invoice.payment_mode}`
            }
            return ''
          },
        },
      ],
    }
  },
  computed: {
    statusList() {
      return [
        {
          text: 'All',
          value: '',
        },
        {
          text: 'CANCELLED',
          value: 'CANCELLED',
        },
        {
          text: 'COMPLETED',
          value: 'COMPLETED',
        },
      ]
    },
  },
  mounted() {
    this.filterData()
  },
  methods: {
    setDate(date) {
      if (date === 0) {
        this.filterDates.stateDate = new Date(moment().add('days', date))
        this.filterDates.endDate = new Date(moment().add('days', date))
      } else if (date === -1) {
        this.filterDates.stateDate = new Date(moment().add('days', date))
        this.filterDates.endDate = new Date(moment().add('days', date))
      } else if (date === -7) {
        this.filterDates.stateDate = new Date(moment().startOf('week'))
        this.filterDates.endDate = new Date(moment().endOf('week'))
      } else if (date === -14) {
        this.filterDates.stateDate = new Date(
          moment()
            .startOf('week')
            .add('days', -7),
        )
        this.filterDates.endDate = new Date(
          moment()
            .endOf('week')
            .add('days', -7),
        )
      } else if (date === -30) {
        this.filterDates.stateDate = new Date(moment().startOf('month'))
        this.filterDates.endDate = new Date(moment().endOf('month'))
      } else if (date === -60) {
        const currentDate = new Date()
        this.filterDates.stateDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
        this.filterDates.endDate = new Date(moment(this.filterDates.stateDate).endOf('month'))
      } else if (date === -365) {
        this.filterDates.stateDate = new Date(moment().startOf('year'))
        this.filterDates.endDate = new Date(moment().endOf('year'))
      } else if (date === -730) {
        const currentDate = new Date()
        this.filterDates.stateDate = new Date(currentDate.getFullYear() - 1, 0, 1)
        this.filterDates.endDate = new Date(moment(this.filterDates.stateDate).endOf('year'))
      }
    },
    filterData() {
      const payload = [this.extraParams]
      payload.push(`from=${moment(this.filterDates.stateDate).format('YYYY-MM-DD')}`)
      payload.push(`to=${moment(this.filterDates.endDate).format('YYYY-MM-DD')}`)
      if (!this.filterDates.status) {
        this.filterDates.status = ''
      } else {
        payload.push(`status=${this.filterDates.status}`)
      }
      this.$refs.statementRef.loadItems(payload.join('&'))
    },
  },
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .wrap-order-transaction-buttons {
    display: none;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
